html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .bg-black {
    background-color: black;
    min-height: 100%;
  }
  
  .navbar-nav .nav-link {
    color: white !important;
  }

  .counter-number{
    color:#7F7F7F;
  }
  
  .img-button {
    border: none;
    background: none;
  }
  
  .rounded-btn {
    border-radius: 25px;
    color:#BAB37F;
    border-color:#7F7F7F ;
    
    
  }

  .rounded-btn2 {
    border-radius: 25px;
    color:#A0B5BA;
    border-color:#7F7F7F ;
  }

  .rounded-btn3 {
    border-radius: 25px;
    color:#7F7F7F;
    border-color:#7F7F7F ;
  }

 
  
  .small-image {
    max-width: 50px; /* Adjust this value according to the desired size */
    height: auto;
  }
  
  .logo-image {
    max-width: 400px; /* Adjust this value according to the desired size */
    height: auto;
  }

  #feedback{
    color:#7F7F7F;
    font-family: helvetica;
    font-weight: 500;
  }